import React, { useState } from "react";
import SecretCodeScreen from "./SecretCodeScreen";
import VideoDisplayScreen from "./VideoDisplayScreen";

const App = () => {
  const [accessGranted, setAccessGranted] = useState(false);

  const validateCode = (code) => {
    return code === "piotr";
  };

  return (
    <div>
      {!accessGranted ? (
        <SecretCodeScreen onAccess={(code) => {
          if (validateCode(code)) setAccessGranted(true);
          else alert("Invalid Code");
        }} />
      ) : (
        <VideoDisplayScreen />
      )}
    </div>
  );
};

export default App;