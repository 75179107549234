import React, { useState } from "react";

const SecretCodeScreen = ({ onAccess }) => {
  const [code, setCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onAccess(code);
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
    }}>
      <h2>Enter Secret Code</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Secret Code"
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SecretCodeScreen;