import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const VideoDisplayScreen = () => {
  const [randomImages, setRandomImages] = useState([]);
  const [imagesEnabled, setImagesEnabled] = useState(true);
  const images = [
    "1.jpg",
    "2.jpg",
    "3.jpg",
    "4.jpg",
    "5.jpg",
    "6.jpg",
    "7.jpg",
    "8.jpg",
    "9.jpg",
    "10.jpg",
    "11.jpg",
    "12.jpg",
    "13.jpg",
    "14.jpg",
    "15.jpg",
    "16.jpg",
    "17.jpg",
    "18.jpg",
    "19.jpg",
    "20.jpg",
  ];

  const imageSize = 200;

  useEffect(() => {
    let interval;

    if (imagesEnabled) {
      interval = setInterval(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];

        // Calculate random positions within the viewport
        const randomX = Math.random() * (window.innerWidth - imageSize);
        const randomY = Math.random() * (window.innerHeight - imageSize);

        setRandomImages((prev) => [
          ...prev,
          { src: randomImage, x: randomX, y: randomY },
        ]);
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [images, imagesEnabled]);

  const toggleImages = () => {
    setImagesEnabled((prev) => !prev);
    if (imagesEnabled) setRandomImages([]);
  };

  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh"}}>
      <button
        onClick={toggleImages}
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "15px 30px",
          fontSize: "18px",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#007BFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 2,
          transition: "background-color 0.3s ease, transform 0.2s ease",
          whiteSpace: "nowrap", // Prevent text wrapping
          overflow: "hidden",   // Hide overflow if text is too long
          textOverflow: "ellipsis", // Add ellipsis for overflow text
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
        onMouseDown={(e) => (e.target.style.transform = "translate(-50%, -50%) scale(0.95)")}
        onMouseUp={(e) => (e.target.style.transform = "translate(-50%, -50%) scale(1)")}
      >
        {imagesEnabled ? "Turn Off Images" : "Turn On Images"}
      </button>
      <video
        src="Krul.mp4"
        controls
        autoPlay
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 0, // Lower z-index ensures it stays behind images
        }}
      />
      {randomImages.map((image, index) => (
        <motion.img
          key={index}
          src={image.src}
          alt="Random"
          style={{
            position: "absolute",
            top: `${image.y}px`,
            left: `${image.x}px`,
            width: `${imageSize}px`,
            height: `${imageSize}px`,
            zIndex: 10, // Higher z-index ensures it appears above the video
          }}
        />
      ))}
    </div>
  );
};

export default VideoDisplayScreen;